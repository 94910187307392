<template>
  <div class="py-8 pr-7">
    <div class="flex justify-between items-center mb-20">
      <h1 class="text-2xl font-bold">System Log</h1>
      <button
        @click="markAll()"
        class="bg-primary py-4 px-7 rounded-xl shadow-lg flex items-center font-bold text-white"
      >
        Mark All As Read
      </button>
    </div>
    <div class="w-full mt-5">
      <div class="flex gap-3 px-5 mb-5">
        <div class="w-1/4">Date</div>
        <div class="w-1/4">Description</div>
        <div class="w-1/4">Staff Name</div>
        <div class="w-1/4"></div>
      </div>
      <div class="px-5" v-if="notifications.length === 0">
        No logs available now
      </div>
      <template v-else>
        <div
          class="flex gap-3 p-3 mb-3 rounded-md shadow-sm bg-white"
          v-for="notification in notifications"
          :key="notification.id"
        >
          <div class="w-1/4">{{ parseDate(notification.updated_at) }}</div>
          <div class="w-1/4">{{ notification.data.message }}</div>
          <div class="w-1/4">
            {{ notification.data.staff.firstname }}
            {{ notification.data.staff.lastname }}
          </div>
          <div class="w-1/4 text-right">
            <div
              class="p-4 bg-red-500 bg-opacity-20 rounded-full cursor-pointer inline-block"
              @click="markSingleAsDone(notification.id)"
            >
              <delete-icon />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  getNotifications,
  markAllAsDone,
  markAsDone,
} from "@/services/settings";

export default {
  name: "SystemLog",
  components: {
    DeleteIcon: () => import("@/assets/icons/DeleteIcon.vue"),
  },
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    parseDate(date) {
      return moment(date).format("D MMM, YYYY");
    },
    async getNotification() {
      try {
        const response = await getNotifications();
        this.notifications = response.data.notification;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
    async markAll() {
      try {
        await markAllAsDone();
        this.$toast.success("Marked all logs as read");
        this.getNotification();
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
    async markSingleAsDone(id) {
      try {
        await markAsDone(id);
        this.$toast.success("Marked as read");
        await this.getNotification();
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
  },
  mounted() {
    this.getNotification();
  },
};
</script>
